import { Excel } from "antd-table-saveas-excel";
import { toast } from "react-toastify";
import exportPDF from "./pdfExports";
export const dropdownvalues = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.name
        m.push(item)
    })
    return m

}
export const dropdownvaluesProjects = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.projectName
        m.push(item)
    })
    return m

}
export const dropdownvaluesProducts = (data) =>{
  let m = []
  data.forEach((item)=>{
      item.value = item.name
      item.label = item.name
      m.push(item)
  })
  return m

}
export const dropdownvaluesUsers = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.fullName
        m.push(item)
    })
    return m

}

export const dropdownvaluesStages = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.name
        m.push(item)
    })
    return m

}
export const dropdownvaluesRegions = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.regionName
        m.push(item)
    })
    return m

}

export const getUserType = () =>{
   let m = localStorage.getItem("userType")
   return m
}
export const getUserId = () =>{
    let u = localStorage.getItem("userId")
    return u
 }

 export const getorg = () =>{
    let u = localStorage.getItem("org_user")
    return u
 }

 export const getIsorg = () =>{
    let u = localStorage.getItem("is_org")
    return u
 }


 export const handleExcelExport = (
    type,
    columns,
    records,
    selectedRowKeys
  ) => {
    const excel = new Excel();
    let cols = []
    columns.forEach((col) => {
      cols.push({
        title: col.title,
        dataIndex: col.dataIndex === "cellEmail" ? "cell" : col.dataIndex,
      });
    });
    if (type == "selected" && !selectedRowKeys?.length > 0) {
      toast.error("Please select record(s)");
      return
    }
    let dataSource = records.filter(function (record) {
      if (type == "selected") {
        return selectedRowKeys?.includes(
        record?.id
        );
      } else {
        return record;
      }
    });


    try {
      excel
        .addSheet("test")
        .addColumns(cols)
        .addDataSource(dataSource, {
          str2Percent: true,
        })
        .saveAs("Excel.xlsx");
    } catch (e) {}
  };

  export const handlePdfExport = (
    type,
    cols,
    records,
    selectedRowKeys
  ) => {
    if (type == "selected" && !selectedRowKeys?.length > 0) {
      toast.error("Please select record(s)");
      return;
    }
    let pdfData = records.filter(function (record) {
      if (type == "selected") {
        console.log("jjjj pdf", selectedRowKeys, records);
        return selectedRowKeys.includes(
         record?.id
        );
      } else {
        return record;
      }
    });

    let jobData = exportProspectiveData(pdfData);
    exportPDF("no", "Jobs Pdf Report", cols, jobData, "portrait");
  };

  const exportProspectiveData = (pdfData) => {
    return pdfData.map((item) => [
      item.name,
      item.address,
      item.ntn,
      item.strn,
      item.contactPerson,
      item.cell,
      item.cnic,
      item.credit
    ]);
  };